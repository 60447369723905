body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "recta_ruccolo";
  src: url(/static/media/recta_ruccolo.be839f5f.eot);
  src: url(/static/media/recta_ruccolo.2b21129f.woff) format("woff");
}

body{
  overflow: hidden;
  height: 100%;
}


*{
  outline:0;
  font-family: recta_ruccolo;
  color: black;
  text-decoration: none;
}


.link{
  border-bottom: 1px solid black;
}

.slick-slide img{
  display: block;
  width: 100vw;
}

.pointer{
    cursor: pointer;
}


header{
  position: fixed;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100vw;
}

header .header_container{
  width: 96vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  top: 2.5vw;
  position: fixed;
}

header .header_container div{
  width: 50%;
}

.dummy{
  width: 100vw;
  position: absolute;
  top: -11vw;
  opacity: 0.1;
  display: none;
}

header .header_container div h1{
  font-size: 8vw;
  margin: 0;
  font-weight: lighter;
}

.counter h1{
  text-align: right;
}



.text_info{
  position: relative;
  left: 2.5vw;
  margin-top: 14.3vw;
  width: 67vw;
  display: -webkit-flex;
  display: flex;
}

.img_info{
  position: relative;
  left: 2.5vw;
  width: 67vw;
  margin-bottom: 2vw;
}

.img_info img {
  width: 45%
}


.text_img_container{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100vw;
  margin-bottom: 2vw;
}

.text_img_container img{
    width: 96vw;
}



.text_info p{
  line-height: 1.3;
  font-size: 1.15vw;
  margin-top: 0.2vw;
}


.text_info div:first-child{
  margin-bottom: 5vw;
}

.text_info div{
  margin-right: 3vw;
  width: 50%;
}


.loading{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: black;
}

.loading h1{
  font-size: 8vw;
  text-transform: uppercase;
  color: white;
}


@media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {

      .slick-slide img {
        height: 100vh !important;
        width: -webkit-fill-available !important;
    }

}

@media only screen and (max-width: 568px) {

  html, body {
  overflow-x: hidden;
  }
  body {
    position: relative;
    height: 100%;
    margin: 0;
  }

  .img{
    overflow: hidden !important;
    height: 100vh;
    margin: 0;
  }

  .slick-slide {
    will-change: transform;
  }

  .text_info div{
    width: 100%;
  }

  .img_info{
    width: 91vw;
    margin-top: 4vw;
  }

  .img_info img{
    width: 100%;
  }



  .slick_container_mobile{
    display: block;
    overflow: hidden;
  }


  .text_info{
    width: 90vw;
  }

  .text_info div:first-child{
    margin-bottom: 4vw;
  }

  .slick-slide img {
    width: 100vw;
    height: auto;
  }

  header .header_container div h1{
    font-size: 13vw;
  }

  .img_mobile{
    display: block;
  }

  .img_mobile img{
    display: block;
    height: 100vh !important;
    width: auto;
  }

  .img_container{
    background-color: white;
    display: -webkit-flex !important;
    display: flex !important;
    height: 100vh !important;
    width: auto;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .text_info{
    bottom: 0;
    position: relative;
    margin-top: 20vw;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .text_info p{
    font-size: 4vw;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    body{
      overflow: hidden;
    }
}

}

